.table {
  text-align: center;
}

.tableHeader {
  color: var(--bs-white);
  background-color: var(--bs-primary);
}

.table tbody tr:hover td {
  background: #dfe9b4;
}

.roundedTopLeft {
  border-top-left-radius: 10px;
}

.roundedTopRight {
  border-top-right-radius: 10px;
}

.modal {
  background: rgba(217, 217, 217, 0.65);
  backdrop-filter: blur(5px);
}

.appliedFilters {
  max-height: 38px;
  overflow: auto;
}
