.container {
  background-color: #f6f6f6;
  border-width: 0px;
}

.data {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 500;
  justify-content: center;
}

.comments {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  justify-content: center;
  align-items: center;
}

.subtitle {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  line-height: 40px;
}

.search {
  color: #b4b0b0;
}

.compareBtn {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  background: #3ba1da;
  border-radius: 8px;
}

.compareBtn:hover {
  background-color: #237ff6;
}

.imagesBtn {
  border-radius: 50%;
  border: none;
  background-color: #d9d9d9;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  display: none;
}

.comment {
  font-size: 15px;
  line-height: 20px;
}

.otherData {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 500;
  justify-content: center;
  line-height: 40px;
}

.backIcon {
  font-size: 30px;
}
