$brand-green: #669a2e;
$brand-dark: #333333;
$brand-white: #ffffff;
$brand-blue: #3ba1da;
$brand-green2: #9dc001;
$brand-red: #e7502f;
$brand-white2: #d9d9d9;
$brand-blue2: #237ff6;
$brand-gray: #f6f6f6;

$theme-colors: (
  'primary': $brand-green,
  'secondary': $brand-blue,
  'success': $brand-green2,
  'danger': $brand-red,
  'dark': $brand-dark,
  'light': $brand-white2,
  'white': $brand-white,
  'gray': $brand-gray
);

$nav-link-color: $brand-green;
$nav-link-hover-color: $brand-green2;
$nav-pills-link-active-bg: $brand-green;

$pagination-active-bg: $brand-green;
$pagination-active-color: $brand-white;
$pagination-color: $brand-green;
$pagination-bg: $brand-white;
$pagination-disabled-bg: $brand-white;

$link-hover-color: $brand-green2;

.btn-primary,
.btn-secondary {
  --bs-btn-color: var(--bs-gray) !important;
  --bs-btn-hover-color: var(--bs-gray) !important;
  --bs-btn-active-color: var(--bs-gray) !important;
}

.navbar-nav {
  --bs-navbar-active-color: var(--bs-gray) !important;
}
