.footer {
  background: #333333;
}

.copyright {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  padding: 1rem;
}

.tracyLink {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  margin-right: 20px;
}

.fbIcon {
  color: #ffffff;
  font-size: 30px;
  margin-right: 20px;
}
