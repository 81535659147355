.background {
  background-image: url('../images/backgroundImg.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.container {
  justify-content: center;
  z-index: 1;
  position: relative;
}

.main_div {
  justify-content: center;
  align-items: center;
  background-color: #ffffff50;
  width: 40vh;
  border-radius: 10px;
  padding: 4rem;
  min-width: 320px;
  min-height: 400px;
  height: calc(60% - (8px + 8px + 98px + 62px + 16px + 16px));
}

.formTitle {
  margin-bottom: 1rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  text-align: center;
  color: #669a2e;
}

.input {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #333333;
}

.check {
  font-family: 'Roboto';
  font-style: normal;
  display: none;
}

.btnDiv {
  text-align: center;
  margin-bottom: 2rem;
}

.btnDivLogout {
  text-align: center;
  margin-top: 4rem;
}

.loginBtn,
.logoutBtn {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  background-color: #3ba1da;
  color: #ffffff;
}

.loginBtn:hover {
  background-color: #237ff6;
}

.resetPassLink {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #333333;
  display: none;
}
