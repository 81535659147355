$font-family-base: 'Roboto', 'Lato', sans-serif;
$sidebar-menu-width-expanded: 200px;

@import './colors';
@import '~bootstrap/scss/bootstrap';
@import '~react-bootstrap-sidebar-menu/dist/sidebar-menu';

html,
body,
#root,
#map {
  min-width: 320px;
  padding: 0;
  width: 100%;
  height: 100%;
}

.main-wrapper {
  width: 100%;
  height: 100%;
  background: var(--bs-gray);
}

.main-header {
  grid-area: header;
  padding: 0.5rem;
}

.main-container {
  grid-area: main;
  box-shadow: inset 2px 1px 9px 1px rgba($dark, 0.1);
  overflow: auto;
  background-color: $gray-100;
  padding-top: calc(1rem + 54px);
}

.sidebar-xs-container {
  min-width: 320px;

  .navbar-brand {
    color: $brand-white !important;

    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;

    img {
      height: 28px;
    }
  }
}

.sidebar-container {
  padding-top: 0;
  flex-direction: column;
  grid-area: sidebar;
  overflow-y: auto;

  span {
    color: $gray-300;
  }

  .sidebar-menu-header {
    height: 64px;
  }

  .sidebar-menu-brand {
    color: $brand-white !important;

    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;

    img {
      height: 28px;
    }
  }

  .sidebar-menu-toggle {
    border: 0;
    width: 54px;
  }

  .sidebar-menu-toggle:focus {
    box-shadow: 0 0 0 0 !important;
  }

  .sidebar-menu-nav-link.active {
    background-color: $brand-green;
    span {
      color: $brand-white;
    }
  }

  hr {
    color: $brand-white !important;
  }
}

.map-info-container {
  background-color: var(--bs-gray-100);
  height: auto;
}

.sticky-top {
  top: 56px;
}

.navbar-container {
  background-color: transparent;

  .navbar-toggler {
    color: var(--bs-white);
    background-color: var(--bs-primary);
  }
}

.form-control:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0.25rem rgba($color: $brand-green2, $alpha: 25%);
}

.loglist-container {
  .loglist-header {
    .skeleton-button {
      background-color: #ffffff00;
      border: 0;
      color: #b4b0b0;
    }

    .skeleton-button:hover {
      color: var(--bs-dark);
    }

    .skeleton-button:active {
      background-color: #ffffff00;
      color: var(--bs-dark);
    }

    th {
      white-space: nowrap;
    }

    h4 {
      font-family: 'Lato';
      font-weight: bold;
    }
  }
}

@include media-breakpoint-up(sm) {
  .main-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'sidebar header'
      'sidebar main';
  }

  .navbar-container {
    background-color: var(--bs-gray);
    padding-top: var(--bs-navbar-padding-y);

    .navbar-toggler {
      color: var(--bs-navbar-color);
      background-color: transparent;
    }
  }

  .main-container {
    padding-top: 1rem;
  }

  .sticky-top {
    top: 0;
  }
}

@include media-breakpoint-up(lg) {
  .map-info-container {
    height: 100%;
  }
}
